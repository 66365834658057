import React, {useState, useEffect} from "react";
import './App.css';
import Axios from 'axios';

function App() {

  const [movieName, setMovieName] = useState('');
  const [review, setReview] = useState('');
  const [movieReviewList, setMovieList] = useState([]);

  const [newReview, setNewReview] = useState("");

  useEffect(() => {
    Axios.get('https://crudbc.rabsconnect.in/api/get').then((response)=> {
      setMovieList(response.data);
    });
  }, []);

  const submitReview = () =>{
    
    Axios.post("https://crudbc.rabsconnect.in/api/insert", {
      movieName:movieName, 
      movieReview:review,
    });

    setMovieList([
        ...movieReviewList, 
        {movieName:movieName, movieReview:review},
    ]);
  };

  const deleteReview = (movie) =>{
    Axios.delete(`https://crudbc.rabsconnect.in/api/delete/${movie}`);
  };

  
  const updateReview = (movie) =>{
    Axios.put("https://crudbc.rabsconnect.in/api/update", {
      movieName:movie, 
      movieReview:newReview,
    });

    setNewReview("");

  };

  return (
    <div className="App">
      <h1>Crud Application</h1> 
      <div className="Form">
        <label>Movie Name : </label>
        <input type="text" placeholder="Enter Moive Name" name="movieName" onChange={(e) => { setMovieName(e.target.value) }} />
        <label>Movie Review : </label>
        <input type="text" placeholder="Enter Movie Review" name="movieReview" onChange ={(e)=>{setReview(e.target.value)}}/>
        <button onClick={submitReview}>Submit</button>
        <div className="cardContainer">
        {movieReviewList.map((val) => {
          return (
            
              <div className="card">
                <h1>{val.movieName}</h1> 
                <p>{val.movieReview}</p>
                
                <button onClick={() => {deleteReview(val.movieName)}}>Delete</button>
                <input type="text" id="updateInput" onChange={(e)=> { 
                  setNewReview(e.target.value)
                }}/>
                <button onClick={()=> {updateReview(val.movieName)}}>Update</button>
              </div>
          );
        })}
        </div>
      </div>
    </div>
  );
}

export default App;
